$(document).ready(function() {
	const width = window.windowBreakPoint();
	width !== 'xs' && width !== 'sm' && ubeWidget.init();

	if (!docCookies.hasItem('rvc')) {
		$('.recently-viewed').addClass('hidden');
	} else {
		$('.recently-viewed').removeClass('hidden');
	};
	
	$('#cp').attr({'href':$bookURL + '/cruisepersonalizer/login.page?company=PC','rel':'Akamai-Prefetch'});
	
    /* 
     * Load dynamic pricing in promo pods 
     */
    var $promoRow = $('.promo-row');
    
	// wait until loc has been defined
	var checkLoc = setInterval(function () {
		if(typeof loc == 'string'){
			clearInterval(checkLoc);
			// Display Get Ready To Sail Info Bar at top of page
			// limit to US user only
			if (loc == 'US') {
				var futureBookingObj = guestProfile.getFutureBooking();

				if (futureBookingObj == null) {
					$(document).on('guestProfile.futureBookingSaved', function () {
						futureBookingObj = guestProfile.getFutureBooking()
						messageBar.init(futureBookingObj,false);
					});
				} else {
					messageBar.init(futureBookingObj,false);
				}
			}
		}
	}, 50);
    		
});

$(document).on('p13nAfterDelivery', function (e, data) {
	if (localStorage.getItem('pcl-ui-alertbar')) {
	  	var pclUIAlertBar = JSON.parse( localStorage.getItem('pcl-ui-alertbar') );
	  	var nowUnixTime = new Date().getUnixTimeStamp();
		if ( nowUnixTime > (pclUIAlertBar["expiry"] + pclUIAlertBar["ttl"])) {
			$(".alert-bar").removeClass('hidden');
			$(document).trigger('headerbar.pinstate');
		}
		} else {
			  $(".alert-bar").removeClass('hidden');
			  $(document).trigger('headerbar.pinstate');
	}
  
	$('.alert-bar-close').on('click', function (e) {
		e.preventDefault();
		var timeToLive = 60 * 60 * 24; //1 day in  seconds
  
		var alertBarCloseTimestamp = new Date().getUnixTimeStamp();
		localStorage.removeItem('pcl-ui-alertbar');
		var alertBarLS = {
			  "expiry" : alertBarCloseTimestamp,
			  "timestamp" : alertBarCloseTimestamp,
			  "ttl": timeToLive
		};
		localStorage.setItem('pcl-ui-alertbar', JSON.stringify(alertBarLS));
		$(".alert-bar").addClass('hidden');
  });

  
  });

 Date.prototype.getUnixTimeStamp = function () {
	return this.getTime()/1000|0 ;
  };
  

var messageBar = {
	/**
	 * [init] - checks to see if booking info bar should be displayed on the homepage
	 */
	init: function (futureBookingObj,displayCount) {
		// make sure futureBooking is available
		if (futureBookingObj != null && futureBookingObj.futureBookings.length > 0) {			
			if (displayCount) {
				messageBar.countDown(futureBookingObj);
			} else {
				messageBar.manage();
			}
		}
	},

	/**
	 * [manage] - inserts client side template of info bar, with link into CP, at the top of the page
	 */
	manage: function () {
		var bar,
			$princessHeader = $('#princess-header');

		if (docCookies.hasItem('sccn')) {
			bar = $('#manage-bar').html();	
		} else {
			bar = $('#login-manage-bar').html();
		}

		//append content markup to dom, and update count down days
		if (typeof bar != 'undefined') {
			$(bar).insertAfter($princessHeader);
		}
	},

	/**
	 * [countDown] - inserts client side template of info bar, with countdown to closest sailing, at the top of the page
	 * @param  {[object]} futureBookingObj - the stored future booking data from pcl_futureBooking
	 */
	countDown: function (futureBookingObj) {

		var closestSailDate = 0;

		//loop through futureBookings to find the itin with the closest sailDate			
		for (var i = 0; i < futureBookingObj.futureBookings.length; i++) {
			// find the closest sailDate
			if (closestSailDate == 0 || closestSailDate > futureBookingObj.futureBookings[i].sailDate) {
				closestSailDate = futureBookingObj.futureBookings[i].sailDate;
			};
		}
		
		var now = (Date.now()) / 1000, // divide by 1000 to remove milliseonds because timestamp in cookie will not include milliseconds
		    countDown = Math.floor( (closestSailDate - now) / (60*60*24) );
			
		// only display info bar when the count down is greater than 1 days
		if (countDown > 1 && $('#get-ready-to-sail-bar').length > 0) {
			var getReadyToSailBar = $('#get-ready-to-sail-bar').html(),
					$princessHeader = $('#princess-header');

			//append content markup to dom, and update count down days
			$(getReadyToSailBar).insertAfter($princessHeader).find('.count-down').text(countDown);
		}
	}
};

/**
 * ubeWidget - asynchronously loading the ube search widget on the homepage
 * @type {Object}
 */

var ubeWidget = {
    init: function () {
        this.loadCSS();
    },
    loadCSS: function () {
        var loadCSSfile = loadCSS('/css/global/ube-search-widget/ube-search-widget.css');
        onloadCSS(loadCSSfile, this.loadJSfile());
    },
    loadJSfile: function () {
        loadJS('/js/global/ube-search-widget/ube-search-widget.js');
    }
};
